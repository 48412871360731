import React from 'react';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import './App.css';
import ArtReferences from "./ArtReferences";
import {Main} from "./Main";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Main/>,
    },
    {
        path: "/art-references",
        element: <ArtReferences/>
    }
]);

function App() {
    return (
        <div className="App">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '100vh',
                paddingBottom: '5em',
            }}>
            <RouterProvider router={router}/>
            {/*<Main/>*/}
            {/*<header className="App-header">*/}
            {/*  <img src={logo} className="App-logo" alt="logo" />*/}
            {/*  <p>*/}
            {/*    Edit <code>src/App.tsx</code> and save to reload.*/}
            {/*  </p>*/}
            {/*  <a*/}
            {/*    className="App-link"*/}
            {/*    href="https://reactjs.org"*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*  >*/}
            {/*    Learn React*/}
            {/*  </a>*/}
            {/*</header>*/}
            </div>
        </div>
    );
}

export default App;
