import React, {ReactNode} from "react";

function BlueskyLink() {
    return (
        <a href={"https://bsky.app/profile/metaflame.dev"} style={{
            display: 'inline',
        }}>🦋 Bluesky</a>
    );
}

function TwitterLink() {
    return (
        <a href={"https://twitter.com/MetaflameDragon"} style={{
            display: 'inline',
        }}>Twitter <i>(X)</i></a>
    );
}

function MainColumn(props: { children: ReactNode }) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: "600px",
            width: "90%"
        }}>
            {props.children}
        </div>
    );
}

// function Separator() {
//     return <div style={{
//         height: "1em",
//         width: "100%",
//     }}></div>;
// }

function BorderedImg(props: { src: string, href: string, alt: string }) {
    return <a href={props.href} style={{
        width: "100%"
    }}>
        <img src={props.src} style={{
            border: "3px solid #addbf2",
            width: "100%"
        }}
             alt={props.alt}/>
    </a>;
}

export {MainColumn, BorderedImg};

export function Main() {
    return (
        <>
            <h1>Hi there, I'm <em>Metaflame!</em></h1>
            <MainColumn>
                <p>
                    I don't currently have much to show for myself on this website.
                    In fact, this whole thing was hacked together at the con
                    after realizing I now have an NFC tag linking to my website.
                    The appearance and design of the website might reflect that.
                </p>
                <p>
                    I'm a robodragon, mainly a programmer interested in gamedev as well as reliable software,
                    but I've also dealt with 3D modeling & animation in Blender, and digital art in Krita.
                </p>
                <p>
                    And, as you might be able to tell, I love robots, dragons, and preferably a mixture of the two.
                </p>
                <p>
                    Click below for more images!
                </p>
                <BorderedImg src={"images/simple_refsheet.jpg"} href={"/art-references"} alt={"Art References"}/>
                <p>
                    You can find me on <BlueskyLink/> primarily these days! I do have a <TwitterLink/> account,
                    but I've gone passive ever since the rebranding. Drop me a follow, if you'd like!
                    Or, if you're meeting me at the con, feel free to ask me for Discord or Telegram.
                </p>
            </MainColumn>
        </>
    )
}