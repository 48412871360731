import {BorderedImg, MainColumn} from "./Main";

type ArtDefinition = { href: string, alt: string }

const art: ArtDefinition[] = [
    {href: "/images/simple_refsheet.jpg", alt: "Simple Refsheet"},
    {href: "/images/refsheet.jpg", alt: "Full Refsheet"},
]

function ArtReferences() {
    return <div>
        <h1>My art & ref sheets!</h1>
        <MainColumn>
        {art.map(link => <BorderedImg src={link.href} href={link.href} alt={link.alt}/>)}
        </MainColumn>
    </div>
}


export default ArtReferences;